@import "@tiller/theme/styles/tiller.css";
@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@700&display=swap');

@font-face {
    font-family: Montserrat;
    src: url("https://fonts.googleapis.com/css?family=Montserrat");
}

.background-flowers {
    background-image: url("./assets/img/flowers-desktop.svg");
}

.mobile-height {
    height: calc(var(--vh, 1vh) * 96);
}

@media (max-width: 1024px) {
    .background-flowers {
        background-image: url("./assets/img/flowers-tablet.svg");
    }
}

@media (max-width: 640px) {
    .background-flowers {
        background-image: url("./assets/img/flowers-mobile.svg");
    }
}