@font-face {
    src: url('../../../assets/font/montserrat/Montserrat-Bold.ttf');
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600 700;
}

:root {
    --indigo-300: #A5B4FC;
    --indigo-600: #4F46E5;
}

.landing-h1, .landing-h2,
.landing-h3, .landing-h4,
.landing-h5, .landing-h6 {
    font-family: 'Montserrat', sans-serif;
    color: black;
}

.landing-p-lg, .landing-p-md, .landing-p-sm {
    font-family: 'Inter', sans-serif;
    color: #334155; /*slate-700*/
    font-weight: 400;
}

.landing-h1, .landing-h2 {
    font-weight: 700;
}

.landing-h3, .landing-h4, .landing-h5, .landing-h6 {
    font-weight: 600;
}

.shadow {
    /* NEW Component Shadow */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.card {
    background: white;
    border-radius: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

mark {
    background-color: #FEF9C3;
    font-weight: bold;
}

.testimonial-image {
    height: 64px;
    width: 64px;
    border-radius: 100%;
}

.collapsible {
    overflow: hidden;
    transition: height 1s ease-in-out;
}

.overflow-hidden {
    overflow: hidden;
}

.nav-item {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}

.nav-item:hover {
    color: var(--indigo-600);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px -2px 0px var(--indigo-600) inset;
}

.active {
    color: var(--indigo-600);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px -2px 0px var(--indigo-600) inset;
}

.footer-item {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: white;
}

.footer-item:hover {
    border-bottom: var(--indigo-300) 1px solid;
    margin-bottom: -1px;
    color: var(--indigo-300);
}

.icon-item {
    cursor: pointer;
}

.icon-item:hover {
    border-bottom: var(--indigo-300) 1px solid;
    margin-bottom: -1px;
}

@media screen and (max-width: 640px) {
    .landing-h1 {
        font-size: 40px;
        line-height: 48px;
    }

    .landing-h2 {
        font-size: 32px;
        line-height: 40px;

    }

    .landing-h3 {
        font-size: 28px;
        line-height: 32px;

    }

    .landing-h4 {
        font-size: 24px;
        line-height: 28px;

    }

    .landing-h5 {
        font-size: 20px;
        line-height: 24px;

    }

    .landing-h6 {
        font-size: 16px;
        line-height: 20px;

    }

    .landing-p-lg {
        font-size: 24px;
        line-height: 36px;

    }

    .landing-p-md {
        font-size: 20px;
        line-height: 28px;

    }

    .landing-p-sm {
        font-size: 16px;
        line-height: 24px;

    }
}

@media screen and (min-width: 640px) {
    .landing-h1 {
        font-size: 64px;
        line-height: 76px;
    }

    .landing-h2 {
        font-size: 52px;
        line-height: 60px;
    }

    .landing-h3 {
        font-size: 40px;
        line-height: 48px;
    }

    .landing-h4 {
        font-size: 28px;
        line-height: 36px;
    }

    .landing-h5 {
        font-size: 22px;
        line-height: 28px;
    }

    .landing-h6 {
        font-size: 16px;
        line-height: 20px;
    }

    .landing-p-lg {
        font-size: 28px;
        line-height: 42px;
    }

    .landing-p-md {
        font-size: 22px;
        line-height: 32px;
    }

    .landing-p-sm {
        font-size: 16px;
        line-height: 24px;
    }
}

